<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础信息管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/electricityMeterRecord">电表记录管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="电表编号" prop="meterNo">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.meterNo"
        ></el-input>
      </el-form-item>
      <el-form-item label="时间范围" prop="rangeTime">
        <el-date-picker
          size="mini"
          v-model="queryModel.rangeTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportXls"
        >导出数据</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      :summary-method="getSummaries"
      show-summary
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="meterNo"
        label="电表编号"
        width="200"
      ></el-table-column>
      <el-table-column prop="dcAv" label="A相电压"></el-table-column>
      <el-table-column prop="dcBv" label="B相电压"></el-table-column>
      <el-table-column prop="dcCv" label="C相电压"></el-table-column>
      <el-table-column prop="dcAa" label="A相电流"></el-table-column>
      <el-table-column prop="dcBa" label="B相电流"></el-table-column>
      <el-table-column prop="dcCa" label="C相电流"></el-table-column>
      <el-table-column prop="dcAp" label="A相有功功率(KW)"></el-table-column>
      <el-table-column prop="dcBp" label="B相有功功率(KW)"></el-table-column>
      <el-table-column prop="dcCp" label="C相有功功率(KW)"></el-table-column>
      <el-table-column
        prop="totalPower"
        label="总有功功率(KW)"
        width="150"
      ></el-table-column>
      <el-table-column prop="positiveActiveKwh" label="正向有功电度(KW*h)"></el-table-column>
      <el-table-column
        prop="intervalSeconds"
        label="传输间隔时间(秒)"
      ></el-table-column>
      <el-table-column prop="kwh" 
        label="用电量(度)" width="150">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="记录时间"
        width="160"
        fixed="right"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import electricityMeterRecordApi from "@/api/base/electricityMeterRecord";
import moment from "moment";

export default {
  data() {
    var self = this;
    const end = new Date();
    const start = new Date();

    start.setTime(end.getTime() - 3600 * 1000 * 24);

    return {
      queryModel: {
        meterNo: "",
        rangeTime: [start, end],
      },
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      cardStatusData: [],
      tableData: [],
      sumKwh: "",
      maxPower: "",
    };
  },
  created() {},
  methods: {
    changePage(pageIndex, exportFlag) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      if (exportFlag != null) {
        formData.append("exportFlag", exportFlag);
        formData.append("pageSize", 10000);
      } else {
        formData.append("pageSize", self.pageSize);
      }

      formData.append("meterNo", self.queryModel.meterNo);

      if (self.queryModel.rangeTime != null) {
        formData.append(
          "rangeTime",
          moment(self.queryModel.rangeTime[0]).format("yyyy-MM-DD HH:mm:ss") +
            "," +
            moment(self.queryModel.rangeTime[1]).format("yyyy-MM-DD HH:mm:ss")
        );
      }

      electricityMeterRecordApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data;

          if (jsonData.result) {
            if (exportFlag) {
              //导出
              self.$message({
                showClose: true,
                type: "success",
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000,
              });
            } else {
              //分页查看
              var page = jsonData.data;

              self.sumKwh = jsonData.data.total;
              self.maxPower = jsonData.data.maxPower;
              self.tableData = page.data;
              self.totalPages = page.totalPages;
              self.totalElements = page.recordsTotal;
            }
          } else {
            self.$message.warning(jsonData.message);
          }
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    exportXls() {
      this.changePage(1, true);
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      sums[0] = "汇总";
      //alert(this.sumKwh)
      sums[10] = "峰值:" + this.maxPower;
      sums[12] = "累计:" + this.sumKwh;
      return sums;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          electricityMeterRecordApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        electricityMeterRecordApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  mounted: function () {
    this.changePage(1);
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>