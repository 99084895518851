import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/base/electricityMeterRecord/pageList", formData);
}

function list(){
    return request.post(constant.serverUrl + "/base/electricityMeterRecord/list");
}

function create(){
    return request.get(constant.serverUrl + "/base/electricityMeterRecord/create");
}
  
function edit(id){
return request.get(constant.serverUrl + "/base/electricityMeterRecord/edit/" + id);
}

function add(formModel){
return request.post(constant.serverUrl + "/base/electricityMeterRecord/add", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function update(formModel){  
return request.post(constant.serverUrl + "/base/electricityMeterRecord/update", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function remove(id){
return request.post(constant.serverUrl + "/base/electricityMeterRecord/delete/" + id);
}

function batchRemove(idList){
return request.post(constant.serverUrl + "/base/electricityMeterRecord/batchDelete",idList,{
    headers: {
    "Content-Type": "application/json"
    }
});
}


export default {
    pageList,create,edit,add,update,remove,batchRemove,list
}